<template>
  <component :is="tooltipOrDiv" class="bitts-avatar" v-bind="tooltipProps">
    <a-avatar
      v-if="showImage"
      class="bitts-avatar--icon"
      :alt="altText || undefined"
      :size="sizeNumber"
      :src="imageURL"
      :shape="!!org ? 'square' : shape"
      :load-error="onLoadError"
      :class="allClasses"
    />
    <a-avatar
      v-else
      class="bitts-avatar--icon flex items-center justify-center"
      :alt="altText || undefined"
      :class="allClasses"
      :size="sizeNumber"
      :shape="!!org ? 'square' : shape"
    >
      <template #icon>
        <div v-if="isIcon" :class="iconClasses" class="bitts-avatar__icon-slot">
          <slot name="icon" />
        </div>
        <BittsSvg v-else-if="customSvg" :svg="customSvg" :class="iconClasses" />
        <div v-else-if="showInitials && initials">
          {{ initials }}
        </div>
        <FontAwesomeIcon
          v-else
          :icon="['fad', icon]"
          class="text-neutral-accent"
          :class="`h-${fallbackIconSize}`"
        />
      </template>
    </a-avatar>
    <template #title>
      {{ tooltipText }}
      <slot name="additionalInfo" />
    </template>
    <slot name="additional-icon" />
  </component>
</template>

<script setup lang="ts">
import { Avatar as AAvatar } from 'ant-design-vue';
import { Md5 } from 'ts-md5';
import { computed, ref } from 'vue';

import BittsSvg from '../BittsSvg/BittsSvg.vue';
import BittsTooltip from '../BittsTooltip/BittsTooltip.vue';

import { Shape, Size, TOrganization, TShape, TSize, TUser } from './types';

export interface Props {
  altTextFromParent?: string;
  size?: TSize | 'copilot';
  shape?: TShape;
  org?: TOrganization | null;
  user?: TUser | null;
  iconSlotBgColor?: string;
  imageSource?: string;
  includeHoverText?: boolean;
  mountTooltipToBody?: boolean;
  fallbackImageType?: string;
  showBorder?: boolean;
  showInitials?: boolean;
  rounded?: boolean;
  isIcon?: boolean;
  isEntity?: boolean;
  entityUrl?: string | null;
  avatarClasses?: string;
  defaultUserImage?: string;
  customSvg?: string;
  forceCircle?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  altTextFromParent: '',
  size: Size.Large,
  shape: Shape.Circle,
  org: null,
  user: null,
  iconSlotBgColor: '',
  imageSource: '',
  includeHoverText: false,
  mountTooltipToBody: false,
  fallbackImageType: 'company',
  showBorder: false,
  showInitials: false,
  rounded: true,
  isIcon: false,
  isEntity: false,
  entityUrl: null,
  avatarClasses: '',
  defaultUserImage: '',
  customSvg: '',
  forceCircle: false,
});

const emit = defineEmits(['loading-failed']);

const errorOnLoad = ref(false);

const orgDomain = computed(() => {
  if (!props.org) return '';
  return props.org.domain || props.org.clearbit_domain || '';
});

const clearbitURL = computed(() => {
  if (!props.org) return '';
  return `https://logo.clearbit.com/${orgDomain.value}`;
});

const gravatarHref = computed(() => {
  if (!props.user) return '';
  if (props.user.picture_url) return props.user.picture_url;
  if (!props.user.email) return '';

  const emailMd5 = Md5.hashStr(props.user.email);
  return 'https://secure.gravatar.com/avatar/'.concat(
    `${emailMd5}?s=300&d=404&r=g`,
  );
});

const imageURL = computed(() => {
  if (props.imageSource) return props.imageSource;
  if (props.org) {
    return props.org.logo_url ? props.org.logo_url : clearbitURL.value;
  }
  if (props.user) {
    return gravatarHref.value ? gravatarHref.value : props.defaultUserImage;
  }
  if (props.isEntity && props.entityUrl) return props.entityUrl;
  return '';
});

const showImage = computed(() => {
  return !!imageURL.value && !errorOnLoad.value;
});

const radiusAmount = computed(() => {
  if ((props.forceCircle || props.shape === 'circle') && !props.org)
    return 'rounded-full';
  return {
    large: 'rounded-12',
    copilot: 'rounded-bts-md',
    medium: 'rounded-bts-md',
    small: 'rounded-bts-sm',
    'x-small': 'rounded-bts-xs',
    tiny: 'rounded-3',
  }[props.size];
});

const fontClassBySize = () => {
  const mapSizeToFontOrg: {
    [key in TSize | 'copilot']: string;
  } = {
    large: 'text-xl font-bold',
    copilot: 'text-m font-bold',
    medium: 'text-sm font-bold',
    small: 'text-xs font-bold',
    'x-small': 'text-xs font-bold',
    tiny: 'text-xs',
  };
  const mapSizeToFontUser = {
    large: 'text-xl font-bold',
    copilot: 'text-m font-bold',
    medium: 'text-sm font-bold',
    small: 'text-xs font-bold',
    'x-small': 'text-xs font-bold',
    tiny: 'text-xs',
  };
  if (showImage.value || !props.showInitials) {
    return '';
  }
  if (props.user) {
    return mapSizeToFontUser[props.size];
  } else if (props.org) {
    return mapSizeToFontOrg[props.size];
  }
  return '';
};

const allClasses = computed(() => {
  const isOrgOrUserPreCheck = !props.isIcon && !props.isEntity;
  const isFallbackIcon = !showImage.value && !props.showInitials;
  const isInitialsView = !showImage.value && props.showInitials;

  const classes: Record<string, boolean> = {
    'border-none':
      !props.showBorder &&
      (props.isEntity || props.isIcon || !!props.customSvg),
    'rounded-none': !props.rounded || props.isEntity,
    'bg-transparent': props.isEntity,
    // org and user background for fallback icon
    'bg-gradient-to-b from-neutral-background-disabled to-neutral-background-weak':
      isFallbackIcon && isOrgOrUserPreCheck && (!!props.user || !!props.org),
    // org background and text color
    'bg-info-background-weak text-info-text font-bold':
      isInitialsView && isOrgOrUserPreCheck && !props.user && !!props.org,
    // user background and text color
    'bg-beta-background-weak text-beta-text font-bold':
      isInitialsView && isOrgOrUserPreCheck && !!props.user && !props.org,
    'bg-primary-background-weak text-primary-accent': props.isIcon,
    [`bitts-avatar__${props.size}`]: true,
  };
  classes[radiusAmount.value] = !props.isEntity && !props.isIcon;
  classes[fontClassBySize()] = true;
  classes[boxShadowClass.value] = !props.isEntity && !props.isIcon;
  if (props.avatarClasses) {
    classes[props.avatarClasses] = true;
  }
  return classes;
});

const altText = computed(() => {
  if (props.altTextFromParent) return props.altTextFromParent;
  if (props.user) {
    if (props.user.first_name) {
      return `${props.user.first_name} ${props.user.last_name}`;
    }
    return props.user.email;
  }
  if (props.org) {
    if (props.org.name) {
      return props.org.name;
    }
    return orgDomain.value;
  }
  // otherwise, it's decorative
  return '';
});

const tooltipText = computed(() => {
  let text = '';
  if (props.user && props.user.first_name) {
    text = props.user.first_name;
    if (props.user.last_name) {
      text += ` ${props.user.last_name}`;
    }
  } else if (props.org && props.org.name) {
    text = props.org.name;
  }
  return text;
});

const icon = computed(() => {
  return props.user || props.fallbackImageType === 'person'
    ? 'user'
    : 'buildings';
});

const iconClasses = computed(() => {
  const classes: Record<string, boolean> = {};
  classes[iconSlotClass.value] = true;
  if (props.iconSlotBgColor) {
    classes[props.iconSlotBgColor] = true;
  }
  return classes;
});

const iconSlotClass = computed(() => {
  return (
    {
      large: 'bitts-avatar__icon-slot-large',
      copilot: 'bitts-avatar__icon-slot-medium',
      medium: 'bitts-avatar__icon-slot-medium',
      small: 'bitts-avatar__icon-slot-small',
      'x-small': 'bitts-avatar__icon-slot-x-small',
      tiny: 'bitts-avatar__icon-slot-tiny',
    } as const
  )[props.size];
});

const boxShadowClass = computed(() => {
  return {
    large: 'shadow-[inset_0_0_0_2px_white] p-2',
    copilot: 'shadow-[inset_0_0_0_1px_white] p-1',
    medium: 'shadow-[inset_0_0_0_1px_white] p-1',
    small: 'shadow-[inset_0_0_0_1px_white] p-1',
    'x-small': 'shadow-[inset_0_0_0_1px_white] p-1',
    tiny: '',
  }[props.size];
});

const sizeNumber = computed(() => {
  const mapSizeNameToNumber = {
    large: 64,
    copilot: 40,
    medium: 32,
    small: 24,
    'x-small': 16,
    tiny: 12,
  };
  return mapSizeNameToNumber[props.size];
});

const tooltipOrDiv = computed(() => {
  return props.includeHoverText ? BittsTooltip : 'div';
});

const tooltipProps = computed(() => {
  const propsData: Record<string, string | boolean> = {};
  if (props.includeHoverText) {
    propsData.trigger = 'hover';
    propsData.placement = 'bottom';
    propsData.mountToBody = props.mountTooltipToBody;
  }
  return propsData;
});

const initials = computed(() => {
  if (props.org && props.org.name) {
    let orgInitials = '';
    const orgWordsArray = props.org.name.split(' ');

    if (orgWordsArray.length > 1) {
      // For multiple words, take the first letter of each of the first two words
      orgInitials = orgWordsArray
        .slice(0, 2)
        .map((word) => word[0]?.toUpperCase())
        .join('');
    } else {
      // For a single word, take the first two letters
      orgInitials = orgWordsArray[0]?.slice(0, 2).toUpperCase() ?? '';
    }

    return ['x-small', 'small'].includes(props.size)
      ? orgInitials[0]
      : orgInitials;
  }
  if (props.user?.first_name) {
    const firstInitial = props.user?.first_name?.toUpperCase()[0] || '';
    const lastInitial = props.user?.last_name?.toUpperCase()[0] || '';
    return ['large', 'medium', 'copilot'].includes(props.size)
      ? firstInitial + lastInitial
      : firstInitial;
  }
  return '';
});

const fallbackIconSize = computed(() => {
  const mapSizeNameToNumber = {
    large: 40,
    copilot: 24,
    medium: 20,
    small: 12,
    'x-small': 8,
    tiny: 4,
  };
  return mapSizeNameToNumber[props.size];
});

const onLoadError = () => {
  errorOnLoad.value = true;
  emit('loading-failed');
  return true;
};
</script>

<style lang="pcss">
.bitts-avatar {
  @apply flex justify-center;
  .ant-avatar > img {
    object-fit: contain;
  }

  .ant-avatar-circle > img {
    @apply !rounded-full;
  }

  .bitts-avatar__large.ant-avatar > img {
    @apply rounded-[10px];
  }

  .bitts-avatar__copilot.ant-avatar > img,
  .bitts-avatar__medium.ant-avatar > img {
    @apply rounded-[6px];
  }

  .bitts-avatar__small.ant-avatar > img {
    @apply rounded-[2px];
  }

  .bitts-avatar__x-small.ant-avatar > img {
    @apply rounded-[1px];
  }

  .ant-avatar {
    font-family: inherit;
  }

  .ant-avatar-circle {
    @apply border border-solid border-neutral-border;
  }

  .ant-avatar-square {
    @apply border border-solid border-neutral-border rounded-bts-base;
  }

  .ant-avatar-square.ant-avatar-icon {
    @apply bg-neutral-background-disabled;
  }

  .ant-avatar.ant-avatar-square.ant-avatar-image {
    @apply p-0 bg-white;
  }

  .ant-avatar.ant-avatar-circle.ant-avatar-image {
    @apply p-0;
  }

  .bitts-avatar__icon-slot {
    @apply flex items-center justify-center h-full w-full;
  }

  .bitts-avatar__icon-slot-large {
    svg {
      @apply h-40;
    }
  }

  .bitts-avatar__icon-slot-medium {
    svg {
      @apply h-20;
    }
  }

  .bitts-avatar__icon-slot-small {
    svg {
      @apply h-12;
    }
  }

  .bitts-avatar__icon-slot-x-small {
    svg {
      @apply h-8;
    }
  }

  svg {
    vertical-align: initial;
  }
}
</style>
